import '../../sass/singles/single-wine.scss'

global.initGmap = () => {
  document.querySelectorAll('.simple-map').forEach((mapEl) => {
    const latitude = parseFloat(mapEl.getAttribute('data-latitude'))
    const longitude = parseFloat(mapEl.getAttribute('data-longitude'))
    const zoom = mapEl.getAttribute('data-zoom') || 8

    const point = {
      lat: latitude,
      lng: longitude
    }

    const gmap = new google.maps.Map(mapEl, {
      center: point,
      zoom: parseInt(zoom, 10)
    })

    new google.maps.Marker({
      position: point,
      map: gmap
    })
  })
}
